import React from 'react'
import styled from '@emotion/styled'

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 500;
    transition: all 0.2s;
    color: ${props => props.theme.colors.text};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.text};
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
    <Item>
    <div class="suodatin">
    <p><b>HUOM!</b></p>
<p>Kaikki sivustolla esiintyvät kasinot toimivat Viron Maksu- ja Tolliametin lisenssillä. Emme esittele muita operaattoreita.</p>
<p><b>VAIN TÄYSI-IKÄISILLE</b></p>
<p>Alaikäisten uhkapelaaminen on laissa kieletty. Sivusto on suunnattu täysi-ikäisille lukijoille.</p>
<p><b>PELAA MALTILLA</b></p>
<p>Rahapelaaminen voi aiheuttaa riippuvuutta. Pelaa aina pelikassallesi sopivilla panoksilla.</p></div>
      </Item>
      <Item>
      </Item>
      <Item>
      </Item>
      <Item>
      <center><img
            className='lazyload'
            data-src="/images/ehdot.webp"
            width='240px'
            height='152px'
            alt="Säännöt ja ehdot pätevät"
          /></center>
      <center><p>contact@virokasino.com</p></center>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
